import React, {useEffect}  from 'react';
import Head from '../components/Head';
import Layout from "../components/Layout";
import Archivelist from '../components/ArchiveList';
import Sharebuttons from '../components/Sharebuttons';

const Post = ({ pageContext: { pgCtxt } }) => {

  useEffect(() => {
    let navBar = document.getElementById("mainNav");
    navBar.classList.add("navbar-shrink");
  }, []);

  return (
    <Layout>
    <Head pageTitle={pgCtxt.post.title} title={pgCtxt.post.title} path={'/posts/' + pgCtxt.post.id} description={pgCtxt.post.briefDescription} imageUrl={pgCtxt.post.image} type="website" datePublished={pgCtxt.post.date} />

    <main class="flex-grow-1 bg-light">
      <div className="container">

        <div className="row post-section mt-4">
            <div className="col-md-9" id="wp_content">

              <h1 className="text-center mt-3">{pgCtxt.post.title}</h1>
              <hr className="divider" />
              <div className="text-center mb-3">
                <i class="bi bi-person-circle text-primary"></i> by {pgCtxt.post.author}, {new Date(pgCtxt.post.date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
              </div>

              { <div dangerouslySetInnerHTML={{ __html: pgCtxt.post.content }} /> }
              <div className="my-3 text-end">
                <Sharebuttons title={pgCtxt.post.title} path={'/posts/' + pgCtxt.post.id} twitterHandle={'@EyedogUS'} tags={pgCtxt.post.keywords} image={pgCtxt.post.image} />
              </div>
            </div>
            <div className="col-md-3">
              <div id="placeholder_postList" className="p-3 bg-light rounded"><Archivelist props={
                {
                  allPosts: pgCtxt.allPosts,
                  postId: pgCtxt.post.id
                }
              }/></div>
            </div>
        </div>

      </div>
    </main>  


  </Layout>
  );


};

export default Post;