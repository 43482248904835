import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon
  } from 'react-share';

const Sharebuttons = ({title, path, twitterHandle, tags, image}) => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                }
            }
        }
    `);
    const url = data.site.siteMetadata.siteUrl + path;

    return (
        <div>
            <span title="Share on Facebook">
                <FacebookShareButton url={url}>
                    <FacebookIcon  size={40} round={true}/>
                </FacebookShareButton>
            </span>
            <span title="Share on Twitter">
                <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
                    <TwitterIcon  size={40} round={true} />
                </TwitterShareButton>
            </span>
            <span title="Share on LinkedIn">
                <LinkedinShareButton url={url} >
                    <LinkedinIcon  size={40} round={true}/>
                </LinkedinShareButton>
            </span>
            <span title="Share on Reddit">
                <RedditShareButton url={url} title={title} >
                    <RedditIcon  size={40} round={true} />
                </RedditShareButton>
            </span>
            <span title="Share on Pinterest">
                <PinterestShareButton url={url} media={image} description={title} >
                    <PinterestIcon size={40} round={true} />
                </PinterestShareButton>
            </span>
        </div>
    );
}

export default Sharebuttons;
